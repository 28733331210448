import './swiper.styl'
import { So as DomSo, Si as DomSi, isolate } from 'drivers/dom.driver'
import { Observable } from 'rxjs'
import { html, TemplateResult } from 'lit-html'
import { map } from 'rxjs/operators'

type So = {
  readonly dom: DomSo
  readonly nodes$: Observable<TemplateResult>
}

type Si = {
  readonly dom: DomSi
}

export const swiper = isolate<So, Si>('pr-swiper', (sources: So): Si => {
  return {
    dom: sources.nodes$.pipe(
      map(nodes => html`
        <div class="swiper">
          ${nodes}
        </div>
      `)
    )
  }
})

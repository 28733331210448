import { So as DomSo, Si as DomSi, DomEvent } from 'drivers/dom.driver'
import { So as LanguageSo } from 'drivers/language.driver'
import { html } from 'lit-html'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

type So = {
  readonly dom: DomSo
  readonly language: LanguageSo
}

type Si ={
  readonly dom: DomSi
  readonly $closeClicked: Observable<DomEvent>
}

export const player = ({ dom, language }: So): Si => {
  return {
    dom: combineLatest([language.symbol]).pipe(
      map(([languageSymbol]) => html`
          ${
            languageSymbol === 'arm'
            ? html`
              <iframe
                class="player__video"
                width="84%"
                height="84%"
                src="https://www.youtube.com/embed/3khN3UsoRuc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            `
            : html`
              <iframe
                class="player__video"
                width="84%"
                height="84%"
                src="https://www.youtube.com/embed/299fgwP2hOk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            `
          }
        </div>
      `)
    ),
    $closeClicked: dom.fromEvent('.player__close', 'click')
  }
}

import './staff.styl'
import { So as DomSo, Si as DomSi } from 'drivers/dom.driver'
import { So as LanguageSo } from 'drivers/language.driver'
import { html } from 'lit-html'
import abo from './assets/abo.png'
import arthur from './assets/arthur.png'
import davo from './assets/davo.png'
import arnak from './assets/arnak.png'
import maga from './assets/maga.png'
import { map } from 'rxjs/operators'

type So = {
  readonly dom: DomSo
  readonly language: LanguageSo
}

type Si = {
  readonly dom: DomSi
}

export const staff = ({ dom, language }: So): Si => {
  return {
    dom: language.texts.pipe(
      map(texts => html`
      <section class="staff">
        <h1 class="staff__title">${texts.STAFF}</h1>
        <div class="staff__employees-list">
          <div class="staff__employee employee">
            <div class="employee__photo">
              <img src="${abo}" />
            </div>
            <div class="employee__info">
              <div class="employee__info-name">${texts.ALBERT_PETSIKYAN}</div>
              <div class="employee__info-position">${texts.BARBER}</div>
            </div>
          </div>
          <div class="staff__employee employee">
            <div class="employee__photo">
              <img src="${arthur}" />
            </div>
            <div class="employee__info">
              <div class="employee__info-name">${texts.ARTHUR_SIMONYAN}</div>
              <div class="employee__info-position">${texts.BARBER}</div>
            </div>
          </div>
          <div class="staff__employee employee">
            <div class="employee__photo">
              <img src="${davo}" />
            </div>
            <div class="employee__info">
              <div class="employee__info-name">${texts.DAVID_GEVORGYAN}</div>
              <div class="employee__info-position">${texts.BARBER}</div>
            </div>
          </div>
          <div class="staff__employee employee">
            <div class="employee__photo">
              <img src="${arnak}" />
            </div>
            <div class="employee__info">
              <div class="employee__info-name">${texts.ARNAK_AMIRKHANYAN}</div>
              <div class="employee__info-position">${texts.BARBER}</div>
            </div>
          </div>
          <div class="staff__employee employee">
            <div class="employee__photo">
              <img src="${maga}" />
            </div>
            <div class="employee__info">
              <div class="employee__info-name">${texts.MARGARITA_TOVMASYAN}</div>
              <div class="employee__info-position">${texts.MANICURIST}</div>
            </div>
          </div>
        </div>
      </section>
    `)
    )
  }
}

import './home.styl'
import { html } from 'lit-html'
import { combineLatest, merge, Observable, of } from 'rxjs'
import { So as DomSo, Si as DomSi } from 'drivers/dom.driver'
import { So as LanguageSo } from 'drivers/language.driver'
import textLogo from './assets/text-logo.svg?inline'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { map, startWith } from 'rxjs/operators'
import { icon } from 'design-system/atoms/icon'
import { slider } from 'design-system/organisms/slider'
import cover1 from './assets/cover-1.jpg'
import cover2 from './assets/cover-2.jpg'
import cover3 from './assets/cover-3.jpg'
import cover4 from './assets/cover-4.jpg'
import { book } from '../../../components/book/book'
import { player } from './player/player'

type So = {
  readonly dom: DomSo
  readonly language: LanguageSo
}

type Si ={
  readonly dom: DomSi
  readonly playerOpened$: Observable<boolean>
}

export const home = ({ dom, language }: So): Si => {
  const bookButton = book({
    dom,
    language,
    type$: of('primary')
  })
  const arrowDown = icon({
    dom,
    type$: of('arrow-down')
  })
  const _slider = slider({
    dom,
    nodes$: of(html`
      <img src="${cover4}">
      <img src="${cover3}">
      <img src="${cover2}">
      <img src="${cover1}">
    `)
  })
  const _player = player({
    dom,
    language
  })

  const playerVisibility$ = merge(
    dom.fromEvent('.player__close', 'click').pipe(map(() => false)),
    dom.fromEvent('.player__background', 'click').pipe(map(() => false)),
    dom.fromEvent('.home__open-player', 'click').pipe(map(() => true))
  ).pipe(
    startWith(false)
  )
  const _closeIcon = icon({ dom, type$: of('close') })
  const _playIcon = icon({ dom, type$: of('play') })

  return {
    dom: combineLatest([
      language.texts,
      bookButton.dom,
      arrowDown.dom,
      _slider.dom,
      _player.dom,
      playerVisibility$,
      _closeIcon.dom,
      _playIcon.dom
    ]).pipe(
      map(([texts, bookButton, arrowDown, slider, player, playerVisibility, closeIcon, playIcon]) => html`
      <section class="home">
        <div class="home__slider">${slider}</div>
        <div class="home__message welcome">
          <div class="welcome__logo">${unsafeSVG(textLogo)}</div>
          <p class="welcome__text">
            ${texts.HOME_SUBTITLE}
          </p>
          <div class="welcome__cta">
            ${bookButton}
          </div>
        </div>
        <div class="home__scroll">
          ${texts.SCROLL}
          <span class="home__scroll-icon">
            ${arrowDown}
          </span>
        </div>
        <div class="home__open-player">
          <span class="home__open-player-icon">
            ${playIcon}
          </span>
          ${texts.OPEN_PLAYER}
        </div>
        <div class="home__video player ${playerVisibility ? 'home__video_open' : ''}">
          <div class="player__background"></div>
          <div class="player__close">${closeIcon}</div>
            ${playerVisibility && player}
          </div>
        </div>
      </section>
    `)
    ),
    playerOpened$: playerVisibility$
  }
}

import { Observable } from 'rxjs'
import { map, shareReplay, startWith } from 'rxjs/operators'
import eng from './languages/eng.yml'
import arm from './languages/arm.yml'
import rus from './languages/rus.yml'
import { getBrowserLanguage, getLanguageFromPath } from '../helpers/language'

export type So = {
  texts: Observable<any>
  symbol: Observable<string>
}

export type Si = Observable<string>

export type LanguageDriver = { (sinks$: Si): So }

export const create = (): LanguageDriver => {
  const languageDriver = (sinks$: Si): So => {
    sinks$.subscribe((symbol) => {
      window.history.pushState(null, null, `/${symbol}`)
    })

    const symbol$ = sinks$.pipe(
      startWith(getLanguageFromPath() || getBrowserLanguage()),
      shareReplay(1)
    )

    const texts = symbol$.pipe(
      map(lang => {
        switch (lang) {
          case 'en':
            return eng
          case 'hy':
            return arm
          case 'ru':
            return rus
        }
      }),
      shareReplay(1)
    )

    return {
      texts: texts,
      symbol: symbol$.pipe(
        shareReplay(1)
      )
    }
  }

  return languageDriver
}

import './contacts.styl'
import { Si as DomSi } from 'drivers/dom.driver'
import { So as LanguageSo } from 'drivers/language.driver'
import { html } from 'lit-html'
import { map } from 'rxjs/operators'

type So = {
  readonly language: LanguageSo
}

type Si = {
  readonly dom: DomSi
}

export const contacts = ({ language }: So): Si => {
  return {
    dom: language.texts.pipe(
      map(texts => html`
        <section class="contacts">
          <h1 class="contacts__title">${texts.CONTACTS}</h1>
          <div class="contacts__map">
            <div class="contacts__map-pointer"></div>
            <div class="contacts__item">
              <div class="contacts__item-label">${texts.PHONE}</div>
              <a class="contacts__item-data" href="tel:+ 374 55 114 144">+ 374 55 114 144</a>
            </div>
            <div class="contacts__item">
              <div class="contacts__item-label">${texts.ADDRESS} 10:00 - 21:00</div>
              <a class="contacts__item-data" href="https://goo.gl/maps/23HkxESWvPNaRj1PA" target="_blank">${texts.PARPETSI}</a>
            </div>
            <a class="contacts__map-link" href="https://goo.gl/maps/23HkxESWvPNaRj1PA" target="_blank">${texts.MAP}</a>
            <div class="contacts__email">
              contact@paragon.am
            </div>
            <div>
              <a class="contacts__social" href="https://www.facebook.com/paragonyerevan" target="_blank">facebook</a>
              <a class="contacts__social" href="https://www.instagram.com/paragonyerevan/" target="_blank">instagram</a>
            </div>
          </div>
        </section>
      `)
    )
  }
}

import './select.styl'
import { So as DomSo, Si as DomSi, isolate } from 'drivers/dom.driver'
import { html, TemplateResult } from 'lit-html'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

type So = {
  readonly dom: DomSo,
  readonly nodes$: Observable<TemplateResult>
}

type Si = {
  readonly dom: DomSi
  readonly value$: Observable<string>
}

export const select = isolate<So, Si>('pr-select', ({ dom, nodes$ }: So) => {
  const value$ = dom.fromEvent('select', 'change').pipe(
    map(e => (<HTMLSelectElement>e.ownTarget).value)
  )

  return {
    dom: nodes$.pipe(
      map((nodes) => html`
        <select>
          ${nodes}
        </select>
      `)
    ),
    value$
  }
})

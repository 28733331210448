import './about.styl'
import { Si as DomSi } from 'drivers/dom.driver'
import { So as LanguageSo } from 'drivers/language.driver'
import { html } from 'lit-html'
import { map } from 'rxjs/operators'

type So = {
  readonly language: LanguageSo
}

type Si = {
  readonly dom: DomSi
}

export const about = ({ language }: So): Si => {
  return {
    dom: language.texts.pipe(
      map(texts => html`
        <section class="about">
          <h1 class="about__title">${texts.MANS_WORLD}</h1>
          <div class="about__text">
            ${texts.ABOUT_FIRST_PARAGRAPH_START}
            <a class="about__music-link" href="https://www.youtube.com/watch?v=H77fRz1rybs" target="_blank">this is a man's world</a>
            ${texts.ABOUT_FIRST_PARAGRAPH_END}
            <br />
            <br />
            ${texts.ABOUT_SECOND_PARAGRAPH}
          </div>
        </section>
    `)
    )
  }
}

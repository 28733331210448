import './app.styl'
import { combineLatest, merge } from 'rxjs'
import { html } from 'lit-html'
import { create as createDomDriver, So as DomSo, Si as DomSi, DomDriver } from 'drivers/dom.driver'
import { create as createWindowDriver, So as WindowSo, Si as WindowSi, WindowDriver, Actions } from 'drivers/window.driver'
import { create as createLanguageDriver, So as LanguageSo, Si as LanguageSi, LanguageDriver } from 'drivers/language.driver'
import { run } from './helpers/runner'
import { map, share, startWith } from 'rxjs/operators'
import { header } from './pages/header/header/header'
import { nav } from './pages/header/nav/nav'
import { main } from './pages/main/main'
import { footer } from './pages/footer/footer'

export type So = {
  readonly dom: DomSo
  readonly window: WindowSo
  readonly language: LanguageSo
}

export type Si = {
  readonly dom: DomSi
  readonly window: WindowSi
  readonly language: LanguageSi
}

type Drivers = {
  readonly dom: DomDriver
  readonly window: WindowDriver
  readonly language: LanguageDriver
}

const app = ({ dom, window, language }: So): Si => {
  const isScrolled$ = window.scroll$.pipe(
    map(scroll => scroll > 68)
  )
  const _header = header({ dom, language })
  const _nav = nav({ dom, language })
  const navToElement$ = _nav.navClicked$.pipe(
    map(target => ({ action: Actions.SCROLL_TO_ELEMENT, element: dom.select(`.${target}`) }))
  )
  const navOpened$ = merge(_header.navClicked$, _nav.closeClicked$, navToElement$).pipe(
    map(() => !dom.select('.app__header-nav').classList.contains('app__header-nav_opened')),
    startWith(false),
    share()
  )
  const _main = main({ dom, language })
  const _footer = footer({ language })

  return {
    dom: combineLatest([_header.dom, _main.dom, _nav.dom, navOpened$, isScrolled$, _footer.dom]).pipe(
      map(([header, main, nav, navOpened, isScrolled, footer]) => html`
        <header>
          <div class="app__header-bar ${isScrolled ? 'app__header-bar_pinned' : ''}">${header}</div>
          <nav class="app__header-nav ${navOpened ? 'app__header-nav_opened' : ''}">${nav}</nav>
        </header>
        <main role="main" class="app__main">
          ${main}
        </main>
        <footer class="app__footer">
          ${footer}
        </footer>
    `)
    ),
    window: merge(
      navOpened$.pipe(
        map(navOpened => ({ action: Actions.BLOCK_SCROLL, state: navOpened }))
      ),
      navToElement$,
      _main.playerOpened$.pipe(
        map(playerOpened => ({ action: Actions.BLOCK_SCROLL, state: playerOpened }))
      )
    ),
    language: _header.language
  }
}

run<Drivers>(app, {
  dom: createDomDriver('#app'),
  window: createWindowDriver(),
  language: createLanguageDriver()
})

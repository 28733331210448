import './header.styl'
import { html } from 'lit-html'
import { combineLatest, Observable, of } from 'rxjs'
import { So as DomSo, Si as DomSi, DomEvent } from 'drivers/dom.driver'
import { Si as LanguageSi, So as LanguageSo } from 'drivers/language.driver'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { map } from 'rxjs/operators'
import { select } from 'design-system/atoms/select'
import { icon } from 'design-system/atoms/icon'
import logo from '../../../assets/logo.svg?inline'

export type So = {
  readonly dom: DomSo
  readonly language: LanguageSo
}

export type Si = {
  readonly dom: DomSi
  readonly navClicked$: Observable<DomEvent>
  readonly language: LanguageSi
}

export const header = ({ dom, language }: So): Si => {
  const languageSelect = select({
    dom,
    nodes$: language.symbol.pipe(
      map((selectedLanguage: string) => {
        return html`
          <option value="hy" .selected=${selectedLanguage === 'hy'}>ARM</option>
          <option value="en" .selected=${selectedLanguage === 'en'}>ENG</option>
          <option value="ru" .selected=${selectedLanguage === 'ru'}>RUS</option>
        `
      })
    )
  })

  const hamburgerIcon = icon({
    dom,
    type$: of('hamburger')
  })

  return {
    dom: combineLatest([languageSelect.dom, hamburgerIcon.dom]).pipe(
      map(([languageSelect, hamburger]) => html`
        <div class="header">
          <div class="header__item">${languageSelect}</div>
          <div class="header__item header__item-logo">${unsafeSVG(logo)}</div>
          <div class="header__item header__item-nav">${hamburger}</div>
        </div>
      `)
    ),
    navClicked$: dom.fromEvent('.header__item-nav pr-icon', 'click'),
    language: languageSelect.value$
  }
}

export const getBrowserLanguage = () => {
  const language = window.navigator.language.split('-')
  return language[0]
}

export const getLanguageFromPath = () => {
  const path = window.location.pathname

  if (path.startsWith('/en')) {
    return 'en'
  }

  if (path.startsWith('/ru')) {
    return 'ru'
  }

  if (path.startsWith('/hy')) {
    return 'hy'
  }

  return null
}

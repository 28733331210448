import './footer.styl'
import { Si as DomSi } from 'drivers/dom.driver'
import { So as LanguageSo } from 'drivers/language.driver'
import { html } from 'lit-html'
import { map } from 'rxjs/operators'

type So = {
  readonly language: LanguageSo
}

type Si = {
 readonly dom: DomSi
}

export const footer = ({ language }: So): Si => {
  return {
    dom: language.texts.pipe(
      map(texts => html`
        <div class="footer">
          <span>${texts.COPYRIGHT}</span>
        </div>
      `)
    )
  }
}

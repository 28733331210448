import './slider.styl'
import { So as DomSo, Si as DomSi, isolate } from 'drivers/dom.driver'
import { html, TemplateResult } from 'lit-html'
import { combineLatest, from, fromEvent, Observable } from 'rxjs'
import { exhaustMap, first, map, mapTo, share, startWith, switchMap } from 'rxjs/operators'

type So = {
  readonly dom: DomSo
  readonly nodes$: Observable<TemplateResult>
}

type Si = {
  readonly dom: DomSi
}

export const slider = isolate<So, Si>('pr-slider', ({ dom, nodes$ }: So): Si => {
  const imagesLoaded$ = dom.render$.pipe(
    first(),
    switchMap(() => from(dom.selectAll('img'))),
    exhaustMap((img:HTMLImageElement) => fromEvent(img, 'load').pipe(first())),
    mapTo(true),
    startWith(false),
    share()
  )

  return {
    dom: combineLatest([nodes$, imagesLoaded$]).pipe(
      map(([nodes, imagesLoaded]) => html`
        <div class="slider ${!imagesLoaded ? 'slider_loading' : ''}">
          ${nodes}
        </div>
      `)
    )
  }
})

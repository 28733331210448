import { Observable, fromEvent } from 'rxjs'
import { animationFrame } from 'rxjs/internal/scheduler/animationFrame'
import { map, throttleTime, startWith, shareReplay } from 'rxjs/operators'

export type ViewPortSize = {
  readonly w: number
  readonly h: number
}

export enum Actions {
  // eslint-disable-next-line no-unused-vars
  SCROLL_TO_ELEMENT,
  // eslint-disable-next-line no-unused-vars
  BLOCK_SCROLL
}

type Action = {
  readonly action: Actions
  readonly element?: Element
  readonly state?: boolean
}

export type So = {
  readonly load$: Observable<Event>
  readonly scroll$: Observable<number>
  readonly resize$: Observable<ViewPortSize>
  readonly hashChange$: Observable<string>
  readonly getElementStyles: (element: Element) => CSSStyleDeclaration
  readonly getScrollPosition: () => number
}

export type Si = Observable<Action>

export type WindowDriver = { (sinks$: Si): So }

export const create = (): WindowDriver => {
  const windowDriver = (sinks$: Si): So => {
    sinks$.subscribe((sink) => {
      switch (sink.action) {
        case Actions.SCROLL_TO_ELEMENT:
          sink.element.scrollIntoView()
          break
        case Actions.BLOCK_SCROLL:
          document.body.style.overflow = sink.state ? 'hidden' : 'initial'
          break
      }
    })

    const getViewPortSize = (): ViewPortSize => ({
      w: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      h: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    })

    const getElementStyles = (element: Element): CSSStyleDeclaration => window.getComputedStyle(element)
    const getScrollPosition = (): number => window.pageYOffset

    const load$ = fromEvent(window, 'load').pipe(
      shareReplay(1)
    )

    const resize$ = fromEvent(window, 'resize').pipe(
      throttleTime(0, animationFrame),
      map(() => getViewPortSize()),
      startWith(getViewPortSize()),
      shareReplay(1)
    )

    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(0, animationFrame),
      map(() => window.pageYOffset),
      startWith(window.pageYOffset),
      shareReplay(1)
    )

    const hashChange$ = fromEvent(window, 'hashchange').pipe(
      throttleTime(0, animationFrame),
      map(() => window.location.hash),
      startWith(window.location.hash),
      shareReplay(1)
    )

    return {
      load$,
      scroll$,
      resize$,
      hashChange$,
      getScrollPosition,
      getElementStyles
    }
  }

  return windowDriver
}

import './icon.styl'
import { html } from 'lit-html'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { So as DomSo, Si as DomSi, isolate } from 'drivers/dom.driver'

import hamburger from './svgs/hamburger.svg?inline'
import arrowDown from './svgs/arrow_down.svg?inline'
import arrowRight from './svgs/arrow_right.svg?inline'
import close from './svgs/close.svg?inline'
import play from './svgs/play.svg?inline'

export type So = {
  readonly dom: DomSo
  readonly type$: Observable<string>
}

export type Si = {
  readonly dom: DomSi
}

export const icon = isolate<So, Si>('pr-icon', ({ type$ }: So) => {
  return {
    dom: type$.pipe(
      map((type) => {
        let icon = null

        switch (type) {
          case 'hamburger':
            icon = hamburger
            break
          case 'arrow-down':
            icon = arrowDown
            break
          case 'arrow-right':
            icon = arrowRight
            break
          case 'close':
            icon = close
            break
          case 'play':
            icon = play
            break
        }

        return html`${unsafeSVG(icon)}`
      })
    )
  }
})


import { So as DomSo, Si as DomSi, isolate } from 'drivers/dom.driver'
import { So as LanguageSo } from 'drivers/language.driver'
import { Observable } from 'rxjs'
import { button } from 'design-system/atoms/button'
import { map } from 'rxjs/operators'

type So = {
  readonly dom: DomSo
  readonly language: LanguageSo
  readonly type$: Observable<string>
}

type Si = {
  readonly dom: DomSi
}

export const book = isolate<So, Si>('pr-book', ({ dom, language, type$ }: So): Si => {
  dom.fromEvent('pr-button', 'click').pipe(
  ).subscribe(() => {
    const url = 'https://www.fresha.com/providers/paragon-xgdnb1t8?pId=494915'
    window.open(url, '_blank')
  })

  const cta = button({
    dom,
    nodes$: language.texts.pipe(
      map(texts => texts.BOOK)
    ),
    type$
  })

  return {
    dom: cta.dom
  }
})

import './services.styl'
import { So as DomSo, Si as DomSi } from 'drivers/dom.driver'
import { So as LanguageSo } from 'drivers/language.driver'
import { combineLatest, of } from 'rxjs'
import { html } from 'lit-html'
import { icon } from 'design-system/atoms/icon'
import { book } from '../../../components/book/book'
import { map } from 'rxjs/operators'
import { swiper } from 'design-system/organisms/swiper'

type So = {
  readonly dom: DomSo
  readonly language: LanguageSo
}

type Si = {
  readonly dom: DomSi
}

export const services = ({ dom, language }: So): Si => {
  const swipeIcon = icon({ dom, type$: of('arrow-right') })
  const bookButton = book({ dom, language, type$: of('secondary full') })

  const priceList = swiper({
    dom,
    nodes$: language.texts.pipe(
      map(texts => html`
        <swiper-item>
          <swiper-body class="price-list">
            <h2 class="price-list__title">${texts.COMBINED_SERVICES}</h2>
            <ul>
              <li class="price-list__item"><span class="price-list__service">${texts.HAIRCUT_BEARD_ROYAL_TRIM}</span><span class="price-list__price">17000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.BUZZ_BEARD_ROYAL_TRIM}</span><span class="price-list__price">14000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.HAIRCUT_BEARD_TRIM}</span><span class="price-list__price">14000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.BUZZ_BEARD_TRIM}</span><span class="price-list__price">11000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.HAIRCUT_SHAVE}</span><span class="price-list__price">16000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.BUZZ_SHAVE}</span><span class="price-list__price">14000 AMD</span></li>
            </ul>
          </swiper-body>
        </swiper-item>
        <swiper-item>
          <swiper-body class="price-list">
            <h2 class="price-list__title">${texts.HAIR}</h2>
            <ul>
              <li class="price-list__item"><span class="price-list__service">${texts.HAIRCUT}</span><span class="price-list__price">9000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.BUZZ}</span><span class="price-list__price">6000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.KIDS_HAIRCUT}</span><span class="price-list__price">6000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.SETTING}</span><span class="price-list__price">4000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.HEAD_SHAVE}</span><span class="price-list__price"> 9000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.HAIR_TONING}</span><span class="price-list__price">6 - 10000 AMD</span></li>
            </ul>
          </swiper-body>
        </swiper-item>
        <swiper-item>
          <swiper-body class="price-list">
            <h2 class="price-list__title">${texts.BEARD}</h2>
            <ul>
              <li class="price-list__item"><span class="price-list__service">${texts.BEARD_ROYAL_TRIM}</span><span class="price-list__price">8000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.BEARD_TRIM}</span><span class="price-list__price">6000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.SHAVE}</span><span class="price-list__price">9000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.ROYAL_SHAVE}</span><span class="price-list__price">12000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.BEARD_TONING}</span><span class="price-list__price">6000 AMD</span></li>
            </ul>
          </swiper-body>
        </swiper-item>
        <swiper-item>
          <swiper-body class="price-list">
            <h2 class="price-list__title">${texts.FACE}</h2>
            <ul>
              <li class="price-list__item"><span class="price-list__service">${texts.FACIAL_SPA}</span><span class="price-list__price">8000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.WAXING}</span><span class="price-list__price">3000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.BLACK_MASK}</span><span class="price-list__price">3000 AMD</span></li>
            </ul>
          </swiper-body>
        </swiper-item>
        <swiper-item>
          <swiper-body class="price-list">
            <h2 class="price-list__title">${texts.HAND_FOOT}</h2>
            <ul>
              <li class="price-list__item"><span class="price-list__service">${texts.HAND_CARE}</span><span class="price-list__price">7000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.FOOT_CARE}</span><span class="price-list__price">13000 AMD</span></li>
              <li class="price-list__item"><span class="price-list__service">${texts.PARAFFIN_THERAPY}</span><span class="price-list__price">4000 AMD</span></li>
            </ul>
          </swiper-body>
        </swiper-item>
    `)
    )
  })

  return {
    dom: combineLatest([language.texts, swipeIcon.dom, bookButton.dom, priceList.dom]).pipe(
      map(([texts, swipeIcon, bookButton, priceList]) => html`
        <section class="services">
          <h1 class="services__title">${texts.SERVICES}</h1>
          <div class="services__prices">
            ${priceList}
          </div>
          <div class="services__swipe-text">
            <div>${texts.SWIPE}</div>
            <div class="services__swipe-text-icon">
              ${swipeIcon}
            </div>
          </div>
          <div class="services__book-button">
            ${bookButton}
          </div>
        </section>
      `)
    )
  }
}

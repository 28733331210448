import { html } from 'lit-html'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { So as DomSo, Si as DomSi } from 'drivers/dom.driver'
import { So as LanguageSo } from 'drivers/language.driver'
import { home } from './home/home'
import { services } from './services/services'
import { staff } from './staff/staff'
import { about } from './about/about'
import { contacts } from './contacts/contacts'

type So = {
 readonly dom: DomSo
 readonly language: LanguageSo
}

type Si = {
  readonly dom: DomSi
  readonly playerOpened$: Observable<boolean>
}

export const main = ({ dom, language }: So): Si => {
  const _home = home({ dom, language })
  const _services = services({ dom, language })
  const _staff = staff({ dom, language })
  const _about = about({ language })
  const _contacts = contacts({ language })

  return {
    dom: combineLatest([_home.dom, _services.dom, _staff.dom, _about.dom, _contacts.dom]).pipe(
      map(([home, services, staff, about, contacts]) => html`
        ${home}
        ${services}
        <!-- ${staff} -->
        ${about}
        ${contacts}
     `)
    ),
    playerOpened$: _home.playerOpened$
  }
}

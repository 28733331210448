import './button.styl'
import { html, TemplateResult } from 'lit-html'
import { combineLatest, Observable } from 'rxjs'
import { So as DomSo, Si as DomSi, isolate } from 'drivers/dom.driver'
import { map } from 'rxjs/operators'

type So = {
  readonly dom: DomSo
  readonly nodes$: Observable<string | TemplateResult>
  readonly type$: Observable<string>
}

type Si = {
  readonly dom: DomSi
  readonly click$: Observable<Event>
}

export const button = isolate<So, Si>('pr-button', ({ dom, nodes$, type$ }: So): Si => {
  return {
    dom: combineLatest([nodes$, type$]).pipe(
      map(([nodes, type]) => html`
        <button class=${type}>
          ${nodes}
        </button>
      `)
    ),
    click$: dom.fromEvent('button', 'click')
  }
})

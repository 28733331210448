import './nav.styl'
import { So as DomSo, Si as DomSi, DomEvent } from 'drivers/dom.driver'
import { So as LanguageSo } from 'drivers/language.driver'
import { html } from 'lit-html'
import { combineLatest, Observable, of } from 'rxjs'
import { icon } from 'design-system/atoms/icon'
import { map, share } from 'rxjs/operators'
import { book } from '../../../components/book/book'

type So = {
  readonly dom: DomSo
  readonly language: LanguageSo
}

type Si = {
  readonly dom: DomSi
  readonly closeClicked$: Observable<DomEvent>
  readonly navClicked$: Observable<String>
}

export const nav = ({ dom, language }: So): Si => {
  const _close = icon({ dom, type$: of('close') })
  const bookButton = book({ dom, language, type$: of('primary full') })
  const navClicked$ = dom.fromEvent('.nav__item', 'click').pipe(
    map(Event => Event.ownTarget.getAttribute('target')),
    share()
  )

  return {
    dom: combineLatest([language.texts, _close.dom, bookButton.dom]).pipe(
      map(([texts, close, bookButton]) => html`
        <div class="nav">
          <div class="nav__close">
            ${close}
          </div>
          <div class="nav__items">
            <a class="nav__item" target="home">${texts.HOME}</a>
            <a class="nav__item" target="services">${texts.SERVICES}</a>
            <!-- <a class="nav__item" target="staff">${texts.STAFF}</a> -->
            <a class="nav__item" target="about">${texts.STORY}</a>
            <a class="nav__item" target="contacts">${texts.CONTACTS}</a>
          </div>
          <div class="nav__footer">
            ${bookButton}
            <a class="nav__address" href="https://goo.gl/maps/23HkxESWvPNaRj1PA" target="_blank">${texts.PARPETSI}</a>
            <a class="nav__phone" href="tel:+ 374 55 114 144">+ 374 55 114 144</a>
          </div>
        </div>
      `)
    ),
    closeClicked$: dom.fromEvent('.nav__close', 'click'),
    navClicked$
  }
}
